/**
 * @author Álvaro Fuentes <alvaro.fuentes.zurita@gmail.com>
 */
import { Client, cacheExchange, fetchExchange } from '@urql/core';
import { Empty, Clockable, Clock, loadConfig } from '@afz/module-library';
import { SsoAppConfig } from './types.js';

export let config: SsoAppConfig;

export let api: Client;

export let clock: Clockable;

function composeUrl(path?: string | URL): URL {
  return new URL(path || '', window.location.origin + import.meta.env.BASE_URL);
}

export async function initBase(): Promise<void> {
  config = await loadConfig(
    composeUrl('config.json'),
    {
      appVendor: 'Currant',
      appTitle: 'SSO',
      appVersion: 'v1.0.0',
      domainJail: 'crri.cl',
      apiUrl: (value: string | Empty): URL => new URL(value || 'https://crri.cl:3032/graphql'),
      sessionDuration: 7 / 24,
      defaultRedirectionUrl: (value: string | Empty): URL => new URL(value || 'https://crri.cl/apps/oneseven/'),
      uiTitle: 'Bienvenido',
      uiSubtitle: 'Ingreso Single Sign-On',
    },
    import.meta.env.MODE
  );

  if (window.location.hostname !== config.domainJail) {
    throw new Error(
      `El dominio de sesión "${config.domainJail}" no corresponde con el modo de aplicación configurado: "${window.location.hostname}"`
    );
  }

  api = new Client({
    url: config.apiUrl.toString(),
    exchanges: [cacheExchange, fetchExchange],
    fetchOptions: { credentials: 'include' },
  });

  clock = Clock.create(); // @todo Usar hora de servidor
}
