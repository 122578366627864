<!--
	@author Álvaro Fuentes <alvaro.fuentes.zurita@gmail.com>
 -->
<script setup lang="ts">
import { getCurrentInstance, nextTick, ref, ComponentPublicInstance } from 'vue';
import { useAppBus, useAuthBus } from '../../bus/mod.js';

type NotifierEmphasis = 'info' | 'question' | 'success' | 'warning' | 'error';

const { $bvToast } = getCurrentInstance()?.proxy as unknown as ComponentPublicInstance;
const app = useAppBus();
const auth = useAuthBus();
const refPassphrase = ref<HTMLInputElement>();

const userName = ref('');
const passphrase = ref('');
const busy = ref(false);

function showNotifier(message: string, title?: string, emphasis?: NotifierEmphasis): void {
  const map: Record<NotifierEmphasis, string> = {
    info: 'default',
    question: 'info',
    success: 'success',
    warning: 'warning',
    error: 'danger',
  } as const;

  $bvToast.toast(message, {
    title: title || 'Error',
    solid: true,
    variant: map[emphasis ?? 'info'],
  });
}

function showAuthError(value: unknown): void {
  const error = (value as { name: string }).name ? (value as { name: string }) : new Error(value as string);

  switch (error.name) {
    case 'UserNameNotFoundError':
    case 'InvalidPassphraseError':
      showNotifier(
        'El nombre de usuario o contraseña es incorrecto. Verifique los datos ingresados y vuelva a intentarlo',
        'Error de acceso',
        'error'
      );
      break;
    default:
      console.error(error);
      showNotifier('Ha ocurrido un error. Por favor intente nuevamente', 'Error desconocido', 'error');
  }
}

function onUsernameKeydown(event: KeyboardEvent): void {
  if (event.key === 'Escape') {
    userName.value = '';
  }
}

function onPassphraseKeydown(event: KeyboardEvent): void {
  if (event.key === 'Escape') {
    passphrase.value = '';
  }
}

async function onSubmit(): Promise<void> {
  if (!userName.value || !passphrase.value) {
    return showNotifier('Debe ingresar usuario y contraseña para continuar', 'Información faltante', 'error');
  }

  busy.value = true;
  try {
    await auth.startSession({
      userName: userName.value,
      passphrase: passphrase.value,
    });

    showNotifier(
      'Usuario debidamente autentificado. Redireccionado a la aplicación. Por favor espere...',
      'Acceso concedido',
      'success'
    );

    setTimeout(function () {
      window.location.href = app.redirectionUrl.toString();
    }, 1000);
  } catch (error) {
    busy.value = false;
    showAuthError(error);
    await nextTick();
    refPassphrase.value?.select();
  }
}

function onReset(): void {
  userName.value = '';
  passphrase.value = '';
}
</script>

<template>
  <div class="c-login-site">
    <h3 class="c-login-site__title">{{ app.config.uiTitle }}</h3>
    <h4 class="c-login-site__subtitle">{{ app.config.uiSubtitle }}</h4>
    <b-form class="c-login-site__form" @reset="onReset" @submit.prevent="onSubmit">
      <b-form-group label="Usuario">
        <b-input-group prepend="🧑">
          <b-form-input
            v-model="userName"
            :disabled="busy"
            placeholder="Ingrese numbre de usuario"
            @keydown.native.stop="onUsernameKeydown($event)"
          />
        </b-input-group>
      </b-form-group>
      <b-form-group label="Contraseña">
        <b-input-group prepend="🔑">
          <b-form-input
            ref="refPassphrase"
            v-model="passphrase"
            :disabled="busy"
            placeholder="Ingrese contraseña"
            type="password"
            @keydown.native.stop="onPassphraseKeydown($event)"
          />
        </b-input-group>
      </b-form-group>
      <div class="c-login-site__actionbar">
        <b-button :disabled="busy" type="submit" variant="primary">Ingresar</b-button>
        <b-button :disabled="busy" type="reset" variant="outline-secondary">Limpiar</b-button>
      </div>
    </b-form>
  </div>
</template>
