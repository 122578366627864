/**
 * @author Álvaro Fuentes <alvaro.fuentes.zurita@gmail.com>
 */
import { defineStore } from 'pinia';
import { api } from '../base.js';

export type CredentialData = {
  userName?: string;

  passphrase?: string;
};

export const useAuthBus = defineStore('auth', function () {
  const CREATE_SESSION_MUTATION = `#graphql
    mutation ($userName: String!, $passphrase: String!) {
      authCreateSession(input: { userName: $userName, passphrase: $passphrase }) {
        userAccount {
          id
          userName
        }
      }
    }
  `;

  async function startSession(data: CredentialData): Promise<void> {
    if (!data.userName || !data.passphrase) {
      return;
    }

    const reply = await api.mutation(CREATE_SESSION_MUTATION, data).toPromise();
    if (reply.error) {
      throw reply.error.graphQLErrors[0].extensions ?? reply.error;
    }
  }

  return { startSession };
});
