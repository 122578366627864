<!--
	@author Álvaro Fuentes <alvaro.fuentes.zurita@gmail.com>
 -->
<script setup lang="ts">
import { useAppBus } from '../../bus/mod.js';

const app = useAppBus();
</script>

<template>
  <div v-if="!app.inited" class="c-preloader">
    <div>Cargando...</div>
  </div>
  <div v-else class="l-root">
    <header class="l-root__header" />
    <div class="l-root__body">
      <router-view />
    </div>
    <footer class="l-root__footer" />
  </div>
</template>
